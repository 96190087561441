import { Field, useField } from 'react-final-form';
import cx, { Argument as ClassNameType } from 'classnames';

import { MDText } from 'i18n-react';

import { FeedbackTooltip } from '@motorway/motorway-storybook-cra';
import { Input } from '@THC/components/Input';

import cypressIds from 'CypressId';

import { composeValidators, getFieldStateWithSubmissionErrors, requiredValidator } from 'Utilities/formValidators';
import { applyCypressData } from 'Utilities/index';

import LocalTexts from '../../AuthenticationTexts.json';
import PasswordStrength from '../PasswordStrength/PasswordStrength';
import { passwordStrengthValidator } from '../PasswordStrength/PasswordStrength.helpers';

import styles from './PasswordField.scss';

const LocalT = new MDText(LocalTexts);
const t = (key:string) => `${LocalT.translate(key)}`;

const texts = {
  label: t('components.passwordField.label'),
  passwordStrengthValidation: t('components.passwordStrength.errorMessage.weak'),
  requiredValidation: t('components.passwordField.errorMessage.default'),
};

const FIELD_NAME = 'password';

type PasswordProps = {
  isFocused?: boolean;
  autoComplete?: string;
  passwordStrength?: boolean;
  label?: string;
  fieldName?: string;
  className?: ClassNameType;
}

const PasswordField = ({
  autoComplete = 'current-password',
  className,
  fieldName = FIELD_NAME,
  isFocused = false,
  label = texts.label,
  passwordStrength = false,
}: PasswordProps) => {
  const { input } = useField<string>(fieldName);
  const passwordValue = input.value;

  const passwordValidators = passwordStrength
    ? composeValidators(
      requiredValidator(texts.requiredValidation),
      passwordStrengthValidator(texts.passwordStrengthValidation),
    ) : requiredValidator(texts.requiredValidation);

  return (
    <div className={cx(styles.passwordField, className)}>
      <Field
        autoComplete={autoComplete}
        autoFocus={isFocused}
        className={className}
        id={fieldName}
        name={fieldName}
        type='password'
        validate={passwordValidators}
        {...applyCypressData(cypressIds.fields.passwordField)}
      >
        {({ meta, ...props }) => (
          <FeedbackTooltip
            className={styles.tooltip}
            content={meta.error || meta.submitError}
            intent={getFieldStateWithSubmissionErrors(meta)}
          >
            <Input
              formProps={{
                ...props,
              }}
              fullWidth={true}
              id={fieldName}
              intent={getFieldStateWithSubmissionErrors(meta)}
              label={label}
              showLabel={true}
              size='medium'
            />
          </FeedbackTooltip>
        )}
      </Field>
      { passwordStrength && passwordValue && <PasswordStrength password={passwordValue} />}
    </div>);
};

export default PasswordField;
