import React, { Children } from 'react';

import { MDText } from 'i18n-react';

import { BUTTON_AS_TYPE, SIZE, VARIANT } from '@motorway/mw-highway-code/enums';
import { Button } from '@THC/components/Button';

import { useBreakpoint } from '../../../../../../../components/misc/Breakpoints/Breakpoints';
import LocalTexts from '../../../../AuthenticationTexts.json';
import useLegacyEmailClient from '../../../../Hooks/useLegacyEmailClient';

import styles from './LegacyEmailSent.scss';

const LocalT = new MDText(LocalTexts);

type Props = {
  email: string;
}

const LegacyEmailSent = ({ email }: Props) => {
  const { getEmailClient, trackClick } = useLegacyEmailClient({ email });
  const { maxWidth: maxDeviceWidth } = useBreakpoint();

  const { breakpointTinyMobile } = maxDeviceWidth || {};

  return (<div className={styles.component}>
    <p className={styles.paragraph}>
      {LocalT.translate('legacySignIn.emailSent.detail')}
    </p>

    <div
      className={styles.emailQuickLink}
    >
      { Children.toArray(getEmailClient()?.map((item) => {
        const { icon, providerName, redirectTo } = item || {};
        return (
          <>
            <Button
              data-thc-button
              fullWidth
              showLabel
              as={ BUTTON_AS_TYPE.ANCHOR }
              href={ redirectTo as string }
              icon={breakpointTinyMobile ? undefined : () => icon as any}
              label={ LocalT.translate('legacySignIn.emailSent.ctaLabel', {
                provider: providerName,
              }) }
              onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => trackClick(e, providerName as string)}
              rel="noreferrer"
              size={ SIZE.MEDIUM }
              target="_blank"
              variant={VARIANT.SECONDARY}
            />
          </>
        );
      }))
      }
    </div>
  </div>);
};

export default LegacyEmailSent;
