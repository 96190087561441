import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';

import { MDText } from 'i18n-react';

import { UserContext } from 'Context/user';
import { VehicleLookupContext } from 'Context/vehicleLookup';

import { GA_TAGS } from 'Utilities/analytics';
import { BUYER_SLUG_LOOKUP, BUYER_SLUGS, ctbEligibility } from 'Utilities/buyers';
import { CRM_TIER } from 'Utilities/constants';

import LocalTexts from '../../AuthenticationTexts.json';
import { PageTitleProps } from '../../helpers';
import useCreateUserAndLogin, { PostUserRequestPayload } from '../../Hooks/useCreateUserAndLogin';
import AuthenticationForm, { SignUpFormValues } from '../AuthenticationForm/AuthenticationForm';
import FormButton from '../FormButton/FormButton';

import SignUpFormFields from './SubComponents/SignUpFormFields/SignUpFormFields';
import TermsAndPrivacy from './SubComponents/TermsAndPrivacy/TermsAndPrivacy';

const LocalT = new MDText(LocalTexts);

const pageTitles = {
  strap: LocalT.translate('signUp.subTitle'),
  title: LocalT.translate('signUp.title'),
} as PageTitleProps;

const EXISTING_ACCOUNT_ERROR = 'Customer already exists.';

const getBuyer = (vehicleLookup: any) => {
  const eligible = vehicleLookup?.marketplaceEligible?.eligible;
  const buyer = BUYER_SLUG_LOOKUP[
    (() => {
      if (eligible) {
        return BUYER_SLUGS.motorway;
      }

      return (vehicleLookup && ctbEligibility(vehicleLookup))
        ? BUYER_SLUGS.ctb
        : BUYER_SLUGS.tcbg;
    })()
  ];
  return buyer;
};

export type SignUpContentProps = {
  onReset: () => void;
  onSubmitHandler?: () => void;
  onSectionTitleChange?: (slug: PageTitleProps) => void;
  isEmailEditable?: boolean;
  isUniquenessChecked?: boolean;
  showSignInLink?: boolean;
}

const SignUpContent = ({
  isEmailEditable = true,
  isUniquenessChecked = false,
  onReset,
  onSectionTitleChange,
  onSubmitHandler,
  showSignInLink = true,
}: SignUpContentProps) => {
  const history = useHistory();

  const { userActions: { update: updateUser }, userState } = useContext(UserContext);
  const {
    vehicleLookupActions: { update: updateVehicleLookup },
    vehicleLookupState: vehicleLookup,
  } = useContext(VehicleLookupContext);
  const buyer = getBuyer(vehicleLookup);

  const onSuccess = ({ consent }: { consent: 'true' | 'false' }) => {
    GA_TAGS.CUSTOMER_CREATED();
    GA_TAGS.AUTH_SIGN_UP_SUBMITTED(buyer);

    GA_TAGS.AUTH_SIGN_UP_CONSENT_SUBMITTED({
      toggled: consent,
      variant: '',
    });

    onSubmitHandler?.();
    window.scrollTo(0, 0);
  };

  const onError = (err: any, payload: PostUserRequestPayload) => {
    GA_TAGS.AUTH_SIGN_UP_FAILED(buyer);
    // Don't believe this error can be naturally triggered, can remove if we do not detect error in logs.
    if (err?.message === EXISTING_ACCOUNT_ERROR) {
      updateUser({ email: payload.email });
      updateVehicleLookup({ owned: true });
      if (!vehicleLookup?.marketplaceEligible?.eligible) {
        history.push('/sign-in');
      }
    }
  };

  const { createUserAndLogin } = useCreateUserAndLogin({ onError, onSuccess });

  useEffect(() => {
    GA_TAGS.AUTH_SIGN_UP(buyer);
  }, [buyer]);

  useEffect(() => onSectionTitleChange?.(pageTitles), []);

  const onSubmit = async ({ consent, email, name, password, phone }: SignUpFormValues) => {
    GA_TAGS.AUTH_SIGN_UP_CLICKED(buyer);
    const crmTier = (consent === 'true') ? CRM_TIER.MARKETING : CRM_TIER.TRANSACTION;
    const payload = {
      email,
      emailCrmTier: crmTier,
      marketingConsent: consent,
      name,
      password,
      phone,
      smsCrmTier: crmTier,
    };

    await createUserAndLogin(payload);
  };

  return (
    <AuthenticationForm<SignUpFormValues>
      initialValues={{ email: userState?.email }}
      onSubmit={(values) => onSubmit(values)} >
      <SignUpFormFields
        initial={userState?.email}
        onReset={() => onReset()}
        {...{ isEmailEditable, isUniquenessChecked, showSignInLink }}/>
      <FormButton type="SIGN_UP" />
      <TermsAndPrivacy />
    </AuthenticationForm>
  );
};

export default SignUpContent;
