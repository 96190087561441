import React from 'react';

import EmailField from '../../../EmailField/EmailField';
import PasswordField from '../../../PasswordField/PasswordField';

import styles from './SignInFormFields.scss';

type Props = {
  initial?: string;
  isCognitoUser?: boolean;
  isNewUserFromSignInRoute?: boolean | undefined;
  onReset: () => void;
}

const SignInFormFields = ({ initial, isCognitoUser, isNewUserFromSignInRoute, onReset }: Props) => (
  <fieldset className={styles.fieldset}>
    <EmailField isEmailEditable={!isCognitoUser} {...{ initial, isNewUserFromSignInRoute, onReset }} />
    { isCognitoUser && <PasswordField autoComplete='new-password' isFocused={true} />}
  </fieldset>);

export default SignInFormFields;
