import { useCallback, useState } from 'react';

import { MDText } from 'i18n-react';

import { postEmailCheck } from 'PrivateAPI';

import useToastNotification from '../../common/Hooks/useToastNotification';
import LocalTexts from '../AuthenticationTexts.json';

const LocalT = new MDText(LocalTexts);

type EmailCheckResponse = {
  action: string;
  cognito: boolean;
  message: string;
  valid: boolean;
};

const ErrorTexts = {
  emailCheckErrorMessage: LocalT.translate('signIn.toastErrors.details'),
  emailCheckErrorTitle: LocalT.translate('signIn.toastErrors.title'),
} as Record<string, string>;

const ErrorMessage = {
  message: ErrorTexts.emailCheckErrorMessage,
  title: ErrorTexts.emailCheckErrorTitle,
};

const useCheckCognitoUser = () => {
  const { showErrorNotification } = useToastNotification();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<EmailCheckResponse | undefined>(undefined);
  const [error, setError] = useState<string | undefined>();

  const checkForCognitoUser = useCallback(async ({ email }: { email: string }) => {
    setIsLoading(true);
    let response: EmailCheckResponse | undefined;
    let errorResponse: string | undefined;

    try {
      response = await postEmailCheck(email);
      errorResponse = undefined;
      setData(response);
    } catch (err) {
      window?.Sentry?.captureException?.(new Error(err as string), {
        extra: { email },
        tags: {
          fetch: 'Check email for cognito user',
        },
      });
      errorResponse = err as string;
      response = undefined;
      setError(errorResponse);
      showErrorNotification({ ...ErrorMessage });
    }
    setIsLoading(false);
    return { data: response, error: errorResponse };
  }, [showErrorNotification]);

  return {
    checkForCognitoUser,
    data,
    error,
    isLoading,
  };
};

export default useCheckCognitoUser;
