import { useCallback, useContext } from 'react';

import { VehicleLookupContext } from 'Context/vehicleLookup';

import { useQuery } from 'Utilities/hooks';

const useCreateDeepLink = () => {
  const { vehicleLookupState } = useContext(VehicleLookupContext);

  const vrm = vehicleLookupState?.vrm ?? '';
  const mileage = vehicleLookupState?.enquiry?.mileage;

  const redirect = useQuery('redirect') || undefined;

  const getRedirectLink = useCallback(() => {
    const buildParams = new URLSearchParams();

    if (mileage) {
      buildParams.set('mileage', mileage?.toString());
    }

    return redirect || `/${vrm}?${buildParams.toString()}`;
  }, [mileage, redirect, vrm]);

  return {
    getRedirectLink,
  };
};

export default useCreateDeepLink;
