import { useContext } from 'react';

import { MDText } from 'i18n-react';

import { postCreateNewUser } from 'PrivateAPI';

import { UserContext } from 'Context/user';

import { CRM_TIER } from 'Utilities/constants';

import useToastNotification from '../../common/Hooks/useToastNotification';
import LocalTexts from '../AuthenticationTexts.json';

const LocalT = new MDText(LocalTexts);

export type PostUserRequestPayload = {
  email: string;
  emailCrmTier: CRM_TIER.MARKETING | CRM_TIER.SERVICE | CRM_TIER.TRANSACTION;
  marketingConsent: 'true' | 'false';
  name: string;
  password: string;
  phone?: string;
  postcode?: string;
  smsCrmTier: CRM_TIER.MARKETING | CRM_TIER.SERVICE | CRM_TIER.TRANSACTION;
}

export type PostUserProps = {
  onError?: (error: any, payload: PostUserRequestPayload) => void;
  onSuccess?: ({ consent }: { consent: 'true' | 'false' }) => void;
}

const text = {
  technicalDifficultiesMessage: LocalT.translate('signUp.toastErrors.technicalDifficulties.details') as string,
  technicalDifficultiesTitle: LocalT.translate('signUp.toastErrors.technicalDifficulties.title') as string,
};

const useCreateUserAndLogin = ({ onError, onSuccess }: PostUserProps) => {
  const { userActions: { update: updateUser } } = useContext(UserContext);
  const { showErrorNotification } = useToastNotification();

  const createUserAndLogin = async (formUserDetailsPayload: PostUserRequestPayload) => {
    const { marketingConsent, ...payload } = formUserDetailsPayload;
    try {
      const response = await postCreateNewUser(payload);
      updateUser({ ...response.data, newSignUp: true });
      onSuccess?.({ consent: marketingConsent });
    } catch (err: any) {
      showErrorNotification({ message: text.technicalDifficultiesMessage, title: text.technicalDifficultiesTitle });
      window?.Sentry?.captureException(new Error(err), {
        tags: {
          fetch: 'Authentication Cognito sign up',
        },
      });
      onError?.(err, formUserDetailsPayload);
    }
  };

  return {
    createUserAndLogin,
  };
};

export default useCreateUserAndLogin;
