import { useCallback, useState } from 'react';

import { MDText } from 'i18n-react';

import { postLoginEmail } from 'PublicAPI';

import { GA_TAGS } from 'Utilities/analytics';

import useToastNotification from '../../common/Hooks/useToastNotification';
import LocalTexts from '../AuthenticationTexts.json';
import { LOGIN_EMAIL_API_RESPONSES as API_RESPONSES } from '../helpers';

const LocalT = new MDText(LocalTexts);

type LegacyEmailProps = {
  deeplink: string;
  email: string;
};

const ErrorTexts = {
  legacyEmailErrorMessage: LocalT.translate('legacySignIn.toastErrors.details'),
  legacyEmailErrorTitle: LocalT.translate('legacySignIn.toastErrors.title'),
} as Record<string, string>;

const useLegacySignInLink = ({ isSignInRoute }: { isSignInRoute: boolean }) => {
  const { showErrorNotification } = useToastNotification();

  const [emailSent, setEmailSent] = useState<string | undefined>(undefined);
  const [apiError, setApiError] = useState<any | undefined>(undefined);

  const sendLegacyEmailLink = useCallback(async (slug: LegacyEmailProps) => {
    const { email } = slug;

    try {
      const { message } = await postLoginEmail(slug);

      if (message === API_RESPONSES.SENT_LOGIN_EMAIL) {
        setEmailSent(email);
        GA_TAGS.AUTH_SIGN_IN_SENT(isSignInRoute);
      }
    } catch (err: any) {
      // TODO maybe change the below GA event details
      // new implementation doesnt show an error screen.
      GA_TAGS.AUTH_SIGN_IN_NO_ACCOUNT(isSignInRoute);

      showErrorNotification({
        message: ErrorTexts.legacyEmailErrorMessage,
        title: ErrorTexts.legacyEmailErrorTitle,
      });
      window?.Sentry?.captureException(new Error(err), {
        extra: { email },
        tags: {
          fetch: 'Authentication Legacy Email',
        },
      });

      setApiError(err);
    }
  }, [isSignInRoute, showErrorNotification]);

  return {
    apiError,
    emailSent,
    sendLegacyEmailLink,
  };
};

export default useLegacySignInLink;
