import { MDText } from 'i18n-react';

import { Hyperlink } from '@motorway/mw-highway-code/alpha';

import LocalTexts from '../../../../AuthenticationTexts.json';

import styles from './TermsAndPrivacy.scss';

const LocalT = new MDText(LocalTexts);

const TermsAndPrivacy = () => (
  <p className={styles.component}>
    {LocalT.translate('components.termsAndPrivacy.message', {
      privacyLink: <span className={styles.noWrap}>
        <Hyperlink as='a' href="/privacy"
          label={LocalT.translate('components.termsAndPrivacy.privacy')} size='small' target="_blank" variant='secondary'/>
      </span>,
      termsLink: (
        <span className={styles.noWrap}>
          <Hyperlink as='a' href="/terms" label={LocalT.translate('components.termsAndPrivacy.terms')} size='small' target="_blank" variant='secondary' />
        </span>
      ),
    })}
  </p>
);

export default TermsAndPrivacy;
