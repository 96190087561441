import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Button } from '@motorway/mw-highway-code';
import { Hyperlink } from '@motorway/mw-highway-code/alpha';
import { VARIANT } from '@motorway/mw-highway-code/enums';
import { InfoBox } from '@THC/components/InfoBox/InfoBox';

import { UserContext } from 'Context/user';
import { VehicleLookupContext } from 'Context/vehicleLookup';

import { GA_TAGS } from 'Utilities/analytics';
import { isUndefined } from 'Utilities/helpers';

import useToastNotification from '../../../common/Hooks/useToastNotification';
import LocalTexts from '../../AuthenticationTexts.json';
import { AUTHENTICATION_URLS, PageTitleProps } from '../../helpers';
import useSignInHooks from '../../Hooks/useSignInHooks';
import AuthenticationForm, { SignInFormValues } from '../AuthenticationForm/AuthenticationForm';
import FormButton from '../FormButton/FormButton';

import LegacyEmailSent from './SubComponents/LegacyEmailSent/LegacyEmailSent';
import SignInFormFields from './SubComponents/SignInFormFields/SignInFormFields';

import styles from './SignInContent.scss';

const LocalT = new MDText(LocalTexts);

type TitleTextProps = {
  readonly isCognitoUser?: boolean;
  readonly legacyEmailSent: string | undefined;
  readonly name: string;
}

export const SignInTitleTexts = {
  strap: LocalT.translate('signIn.subTitle'),
  title: LocalT.translate('signIn.title'),
} as PageTitleProps;

const updateTitleTexts = ({ isCognitoUser, legacyEmailSent, name }: TitleTextProps) => {
  if (!isUndefined(legacyEmailSent)) {
    return {
      strap: LocalT.translate('legacySignIn.subTitle'),
      title: LocalT.translate('legacySignIn.title'),
    };
  }

  return (!isCognitoUser
    ? SignInTitleTexts
    : {
      strap: LocalT.translate('signIn.passwordSubtitle'),
      title: name
        ? LocalT.translate('signIn.passwordTitle', { name })
        : LocalT.translate('signIn.title'),
    });
};

export type SignInContentProps = {
  isSignInRoute: boolean;
  onNewUser?: () => void;
  onSectionTitleChange?: (slug: PageTitleProps) => void;
}

const SignInContent = ({ isSignInRoute, onNewUser, onSectionTitleChange }: SignInContentProps) => {
  const { userActions, userState } = useContext(UserContext);
  const { vehicleLookupState: vehicleLookup } = useContext(VehicleLookupContext);
  const [isCognitoUser, setIsCognitoUser] = useState<boolean | undefined>(false);
  const [newSignUp, setNewSignUp] = useState<boolean | undefined>(undefined);

  const {
    checkForCognitoUser,
    getRedirectLink,
    legacyEmailSent,
    loginCognitoUser,
    sendLegacyEmailLink,
  } = useSignInHooks({ isSignInRoute });

  const { showErrorNotification } = useToastNotification();

  const onSubmit = async ({ email, password }: SignInFormValues) => {
    userActions.update({ email });

    const { data, error } = await checkForCognitoUser({ email });

    if (!isUndefined(error)) {
      return;
    }

    const redirectTo = getRedirectLink();
    const { cognito } = data || {};

    const redirectRoute = isSignInRoute && isUndefined(vehicleLookup?.vrm) ? '/account' : redirectTo;

    const isCognitoDefined = !isUndefined(cognito);
    setIsCognitoUser(cognito);
    const isCognitoSignInLoad = isCognitoDefined && cognito;
    const isCognitoSignInSubmitted = isCognitoSignInLoad && email && password;
    const isLegacySignInLoad = isCognitoDefined && !cognito;
    const isNewSignUp = !isCognitoDefined;
    setNewSignUp(!isCognitoDefined);

    if (isNewSignUp && !isSignInRoute) {
      if (vehicleLookup?.owned) {
        GA_TAGS.AUTH_SIGN_IN_NO_ACCOUNT_VRM();
      }
      onNewUser?.();
    } else if (isNewSignUp && isSignInRoute) {
      GA_TAGS.AUTH_SIGN_IN_NO_ACCOUNT(isSignInRoute);
      showErrorNotification({
        message: LocalT.translate('signIn.toastErrors.notFound.details') as string,
        title: LocalT.translate('signIn.toastErrors.notFound.title') as string,
      });
    }

    if (isLegacySignInLoad) {
      await sendLegacyEmailLink({ deeplink: redirectTo, email });
    } else if (isCognitoSignInLoad && !isCognitoSignInSubmitted) {
      GA_TAGS.AUTH_SIGN_IN_PASSWORD(isSignInRoute);
    }

    if (isCognitoSignInSubmitted) {
      await loginCognitoUser({ email, password, redirectTo: redirectRoute });
    }
  };

  useEffect(() => {
    const titleTexts = updateTitleTexts({
      isCognitoUser,
      legacyEmailSent,
      name: userState?.name ?? '',
    }) as PageTitleProps;
    onSectionTitleChange?.(titleTexts);
  }, [isCognitoUser, legacyEmailSent, userState?.name]);

  const noAccount = newSignUp && isSignInRoute;

  return (
    isUndefined(legacyEmailSent)
      ? (
        <>
          <AuthenticationForm<SignInFormValues>
            initialValues={{ email: userState?.email }}
            onSubmit={(values) => onSubmit(values)}
            validateOnBlur={false}>
            <SignInFormFields
              initial={userState?.email}
              isNewUserFromSignInRoute={noAccount}
              onReset={() => setIsCognitoUser(false)}
              {...{ isCognitoUser }} />
            <FormButton type={isCognitoUser ? 'SIGN_IN' : 'EMAIL_CHECK'}/>
          </AuthenticationForm>
          { userState?.email && (
            <div className={styles.buttonWrapper}>
              <Button
                fullWidth
                as={Link}
                icon="chevron"
                label={LocalT.translate('forgotPassword.title')}
                onClick={() => GA_TAGS.AUTH_SIGN_IN_FORGOT_PASSWORD_LINK_CLICKED()}
                to={AUTHENTICATION_URLS.FORGOT_PASSWORD}
                variant={VARIANT.TERTIARY}
              />
            </div>
          )}
          { noAccount && (
            <div className={cx(styles.infoBoxWrapper, { [styles.visible]: noAccount })}>
              <InfoBox
                content={LocalT.translate('signIn.infoBox.content', {
                  link: <Hyperlink
                    as={Link}
                    label={LocalT.translate('signIn.infoBox.label')}
                    to="/"
                  />,
                })}
                heading={LocalT.translate('signIn.infoBox.title')}
              />
            </div>
          )}
        </>
      ) : (
        <LegacyEmailSent email={userState?.email ?? ''}/>
      )
  );
};

export default SignInContent;
