/* eslint-disable import/prefer-default-export */
import { getUser, getUserLogOut, postCognitoUserLogin } from 'PrivateAPI';

import { establishCognitoSession, UserDispatch } from 'Context/user';
import { VehicleDispatch } from 'Context/vehicles';

const updateUser = (user: any) => {
  const { vehicles, ...seller } = user;

  // @ts-ignore
  UserDispatch.actions.update(seller);

  if (vehicles) {
    // @ts-ignore
    VehicleDispatch.actions.replace(vehicles);
  }
};

type CognitoLoginRequestBody = {
  username: string;
  password: string;
}

export const loginUser = async ({ password, username }: CognitoLoginRequestBody) => {
  const { data: cognitoTokens, error: apiError } = await postCognitoUserLogin({ password, username })
    .catch((error) => ({ error }));

  const result = { data: cognitoTokens, error: apiError };

  // API Error
  if (result.error) {
    window?.Sentry?.captureException?.(new Error(result.error), {
      extra: { email: username },
      tags: {
        fetch: 'Cognito Sign in',
      },
    });

    return result;
  }

  const { error: loginError, success: loginSuccess } = await establishCognitoSession(cognitoTokens);

  try {
    if (loginSuccess === false) {
      throw loginError || new Error('Cognito Login unsuccessful');
    }

    const customer = await getUser({ include: 'tracker' });

    if (Number.isInteger(customer?.id)) {
      updateUser(customer);
      result.data = customer;
    } else {
      throw new Error('Cognito Login User seller ID is not an integer');
    }
  } catch (err: any) {
    result.error = err;
    result.data = undefined;

    window?.Sentry?.captureException?.(new Error(err), {
      extra: { email: username },
      tags: {
        fetch: 'Cognito Sign in',
      },
    });

    await getUserLogOut();
  }

  return result;
};
