import { useCallback } from 'react';
import { useHistory } from 'react-router';

import { MDText } from 'i18n-react';

import { loginUser } from 'Context/authActions';

import useToastNotification from '../../common/Hooks/useToastNotification';
import LocalTexts from '../AuthenticationTexts.json';

const LocalT = new MDText(LocalTexts);

type CognitoLoginProps = {
  email: string;
  password: string;
  redirectTo: string;
};

const API_RESPONSES = Object.freeze({
  INCORRECT_CREDENTIALS: 'Incorrect username or password.',
  PASSWORD_ATTEMPTS_EXCEEDED: 'Password attempts exceeded',
});

const ErrorTexts = {
  incorrectCredentialsMessage: LocalT.translate('cognitoSignIn.toastErrors.incorrectCredentials.details'),
  incorrectCredentialsTitle: LocalT.translate('cognitoSignIn.toastErrors.incorrectCredentials.title'),

  passwordExceededMessage: LocalT.translate('cognitoSignIn.toastErrors.passwordExceededAttempts.details'),
  passwordExceededTitle: LocalT.translate('cognitoSignIn.toastErrors.passwordExceededAttempts.title'),

  technicalDifficultiesMessage: LocalT.translate('cognitoSignIn.toastErrors.technicalDifficulties.details'),
  technicalDifficultiesTitle: LocalT.translate('cognitoSignIn.toastErrors.technicalDifficulties.title'),
} as Record<string, string>;

const fetchErrorTexts = (message: any) => {
  switch (true) {
    case message?.includes(API_RESPONSES.INCORRECT_CREDENTIALS):
      return {
        message: ErrorTexts.incorrectCredentialsMessage,
        title: ErrorTexts.incorrectCredentialsTitle,
      };

    case message?.includes(API_RESPONSES.PASSWORD_ATTEMPTS_EXCEEDED):
      return {
        message: ErrorTexts.passwordExceededMessage,
        title: ErrorTexts.passwordExceededTitle,
      };

    default:
      return {
        message: ErrorTexts.technicalDifficultiesMessage,
        title: ErrorTexts.technicalDifficultiesTitle,
      };
  }
};

const useCognitoLogin = () => {
  const history = useHistory();

  const { showErrorNotification } = useToastNotification();

  const loginCognitoUser = useCallback(async (slug: CognitoLoginProps) => {
    const { email, password, redirectTo } = slug;
    try {
      const { data: customer, error } = await loginUser({ password, username: email }) || {};

      if (customer?.id) {
        history.replace(redirectTo);
      }

      if (error?.message) {
        throw new Error(error);
      }
    } catch (err: any) {
      const isIncorrectCredentials = err?.message?.includes(API_RESPONSES.INCORRECT_CREDENTIALS);
      const errorMessage = fetchErrorTexts(err?.message);

      showErrorNotification(errorMessage);

      if (!isIncorrectCredentials) {
        window?.Sentry?.captureException(new Error(err));
      }
    }
  }, [history, showErrorNotification]);

  return {
    loginCognitoUser,
  };
};

export default useCognitoLogin;
