import React, { ReactElement, useCallback } from 'react';

import { Icon } from '@motorway/mw-highway-code';
import { SIZE } from '@motorway/mw-highway-code/enums';

import { GAEvent } from 'Utilities/analytics';
import { isIOS, isMobileDevice } from 'Utilities/devices';

import { ReactComponent as EmailIconAOL } from '../../../../../client/assets_seller/new_email_logos/aol.svg';
import { ReactComponent as EmailIconBTInternet } from '../../../../../client/assets_seller/new_email_logos/bt.svg';
import { ReactComponent as EmailIconGmail } from '../../../../../client/assets_seller/new_email_logos/gmail.svg';
import { ReactComponent as EmailIconMail } from '../../../../../client/assets_seller/new_email_logos/iosMail.svg';
import { ReactComponent as EmailIconOutlook } from '../../../../../client/assets_seller/new_email_logos/outlook.svg';
import { ReactComponent as EmailIconYahoo } from '../../../../../client/assets_seller/new_email_logos/yahoo.svg';
import { EMAIL_PROVIDERS } from '../helpers';

const useLegacyEmailClient = ({ email }: { email: string }) => {
  const trackClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, provider: string | null) => {
    /**
     * Security model won't let window.open (new tab) fire in a callback
     * So fire and forget, but on iOS we can redirect after callback
     */

    const href = e.currentTarget.getAttribute('href');
    const iOSLink = href?.search('http') !== 0;

    if ((window as any).google_tag_manager) {
      if (iOSLink) {
        e.preventDefault();
      }

      GAEvent({
        email_provider_clicked: provider,
        event: 'email_provider_clicked',
        eventCallback() {
          if (iOSLink && href) {
            window.location.href = href;
          }
        },
        eventTimeout: 300,
      });
    }
  };

  const getIconComponentForButton = (icon: ReactElement) => <Icon size={SIZE.XSMALL}>{icon}</Icon>;

  const getEmailClient = useCallback(() => {
    if (email && email.split('@')[1]) {
      const domain = email.split('@')[1].toLowerCase();

      let emailProvider;
      const emailOptions = [];

      switch (true) {
        case /(hotmail|outlook|live.co|msn.co|windowslive.co)/.test(domain):
          emailProvider = [...EMAIL_PROVIDERS.OUTLOOK, getIconComponentForButton(<EmailIconOutlook />)];
          break;
        case /(gmail|googlemail|google|motorway.co.uk)/.test(domain):
          emailProvider = [...EMAIL_PROVIDERS.GMAIL, getIconComponentForButton(<EmailIconGmail />)];
          break;
        case /(yahoo|ymail)/.test(domain):
          emailProvider = [...EMAIL_PROVIDERS.YAHOO, getIconComponentForButton(<EmailIconYahoo />)];
          break;
        case /btinternet/.test(domain):
          emailProvider = [...EMAIL_PROVIDERS.BT, getIconComponentForButton(<EmailIconBTInternet />)];
          break;
        case /aol.co/.test(domain):
          emailProvider = [...EMAIL_PROVIDERS.AOL, getIconComponentForButton(<EmailIconAOL />)];
          break;
        case /sky.com/.test(domain):
          emailProvider = [...EMAIL_PROVIDERS.SKY, null];
          break;
        // no default
      }

      if (emailProvider) {
        const [providerName, providerUrl, providerShortUrl, emailIcon] = emailProvider;
        const redirectTo = isIOS() ? providerShortUrl : providerUrl;

        if (redirectTo) {
          emailOptions.push({
            icon: emailIcon,
            providerName,
            redirectTo,
          });
        }
      }

      if (isMobileDevice({}) && isIOS()) {
        emailOptions.push({
          icon: getIconComponentForButton(<EmailIconMail />),
          providerName: 'Apple Mail',
          redirectTo: 'message:',
        });
      }

      GAEvent({
        email_provider_clicked: `Not known - ${domain}`,
        event: 'email_provider_clicked',
      });
      return emailOptions;
    }

    return null;
  }, [email]);

  return {
    getEmailClient,
    trackClick,
  };
};

export default useLegacyEmailClient;
