import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { UseToastProvider } from '@motorway/motorway-storybook-cra';

import { VehicleLookupContext } from 'Context/vehicleLookup';

import { GA_TAGS } from 'Utilities/analytics';
import { useQuery } from 'Utilities/hooks';

import PageLayout from '../../Components/PageLayout/PageLayout';
import SectionHeader from '../../Components/SectionHeader/Header';
import SignInContent, { SignInTitleTexts } from '../../Components/SignInContent/SignInContent';
import SignUpContent from '../../Components/SignUpContent/SignUpContent';
import { PageTitleProps } from '../../helpers';

import styles from './RegistrationPage.scss';

export type RegistrationPageProps = {
  isEmbedded?: boolean;
}

const RegistrationPage = ({ isEmbedded = true }: RegistrationPageProps) => {
  const { vehicleLookupState: vehicleLookup } = useContext(VehicleLookupContext);
  const { pathname } = useLocation();
  const [showSignUpForm, setShowSignUpForm] = useState<boolean>(false);
  const [pageTitle, setPageTitle] = useState<PageTitleProps>(SignInTitleTexts);
  const isRedirectedFromReset = useQuery('redirectedFromNewPassword');

  const isSignInRoute = pathname?.includes('/sign-in');
  const isVehicleOwned = vehicleLookup?.owned;

  useEffect(() => {
    if (isVehicleOwned || isSignInRoute) {
      setShowSignUpForm(false);
    } else {
      setShowSignUpForm(true);
    }
  }, [isVehicleOwned, isSignInRoute]);

  useEffect(() => {
    GA_TAGS.AUTH_SIGN_IN(isSignInRoute);
    if (isRedirectedFromReset === 'true') {
      GA_TAGS.AUTH_FORGOT_PASSWORD_REDIRECT_TO_SIGN_IN_PAGE();
    }
  }, [isSignInRoute, isRedirectedFromReset]);

  return (
    <UseToastProvider>
      <PageLayout {...{ isEmbedded, pageTitle }}>
        <div className={styles.component}>
          {isEmbedded && <SectionHeader {...{ ...pageTitle }} />}
          { showSignUpForm
            ? <SignUpContent
              isEmailEditable={!isVehicleOwned}
              isUniquenessChecked={!isVehicleOwned}
              onReset={() => setShowSignUpForm(false)}
              onSectionTitleChange={(slug) => setPageTitle(slug)}
            />
            : <SignInContent
              isSignInRoute={isSignInRoute}
              onNewUser={() => setShowSignUpForm(true)}
              onSectionTitleChange={(slug) => setPageTitle(slug)}
            />
          }
        </div>
      </PageLayout>
    </UseToastProvider>
  );
};

export default RegistrationPage;
