import React from 'react';

import ConsentToggle from '../../../ConsentToggle/ConsentField';
import EmailField from '../../../EmailField/EmailField';
import NameField from '../../../NameField/NameField';
import PasswordField from '../../../PasswordField/PasswordField';
import PhoneField from '../../../PhoneField/PhoneField';

import styles from './SignUpFormFields.scss';

type Props = {
  initial?: string;
  onReset: () => void;
  isEmailEditable: boolean;
  isUniquenessChecked: boolean;
}

// TODO: pass init state to each field
const SignUpFormFields = ({ initial, isEmailEditable = false, isUniquenessChecked, onReset }: Props) => (
  <fieldset className={styles.fieldset}>
    <EmailField {...{ initial, isEmailEditable, isUniquenessChecked, onReset }} />
    <NameField />
    <PhoneField />
    <PasswordField passwordStrength={true} />
    <ConsentToggle />
  </fieldset>
);

export default SignUpFormFields;
