import React, { useMemo } from 'react';

import { MDText } from 'i18n-react';

import { TickList } from '@motorway/motorway-storybook-cra';

import LocalTexts from '../../AuthenticationTexts.json';

import {
  hasLowercaseAndUppercase,
  hasMinimumLength,
  hasNumber,
  hasSpecialCharacter,
} from './PasswordStrength.helpers';

import styles from './PasswordStrength.scss';

const LocalT = new MDText(LocalTexts);

type PasswordStrengthConditions = {
  [key: string]: boolean
}

type PasswordStrengthProps = {
  password: string;
}

const PasswordStrength = ({ password }: PasswordStrengthProps) => {
  const passwordChecks: PasswordStrengthConditions = useMemo(() => ({
    hasLowercaseAndUppercase: hasLowercaseAndUppercase(password),
    hasNumber: hasNumber(password),
    hasSpecialCharacter: hasSpecialCharacter(password),
    minimumLength: hasMinimumLength(password),
  }), [password]);

  return (
    <div className={styles.component}>
      <div className={styles.tickList}>
        {
          Object.keys(passwordChecks).map((type) => (
            <TickList
              key={type}
              className={{ [styles.greyIcon]: !passwordChecks[type] }}
              data={[LocalT.translate(`components.passwordStrength.rules.${type}`)]}
            />
          ))
        }
      </div>
    </div>
  );
};

export default PasswordStrength;
