import React from 'react';

import { PageTitleProps } from '../../helpers';

import styles from './Header.scss';

const Header = ({ strap, title }: PageTitleProps) => (
  <div className={styles.component}>
    { title && (<h1 className={styles.title}>{ title }</h1>) }
    { strap && (<p className={styles.subTitle}>{ strap }</p>) }
  </div>
);

export default Header;
