export const hasNumber = (password: string) => /\d/.test(password);
export const hasMinimumLength = (password: string, length:number = 8) => password.length >= length;
export const hasLowercaseAndUppercase = (password: string) => /^(?=.*[a-z])(?=.*[A-Z]).*$/.test(password);
export const hasSpecialCharacter = (password: string) => /[ `!@£#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password);

export const PASSWORD_ERROR_REASONS = {
  WEAK: 'weak',
};

export const passwordErrors = {
  hasLowercaseAndUppercase: {
    helper: hasLowercaseAndUppercase,
    id: 'hasLowercaseAndUppercase',
  },
  hasMinimumLength: {
    helper: hasMinimumLength,
    id: 'hasMinimumLength',
  },
  hasNumber: {
    helper: hasNumber,
    id: 'hasNumber',
  },
  hasSpecialCharacter: {
    helper: hasSpecialCharacter,
    id: 'hasSpecialCharacter',
  },
};

export const getErrors = (password: string) => Object.values(passwordErrors).filter((error) => !error.helper(password));

export const passwordStrengthValidator = (errorMessage:string) => (val:string) => {
  const isValid = getErrors(val)?.length === 0;
  return isValid ? undefined : errorMessage;
};
