import useCheckCognitoUser from './useCheckCognitoUser';
import useCognitoLogin from './useCognitoLogin';
import useCreateDeepLink from './useCreateDeepLink';
import useLegacySignInLink from './useLegacySignInLink';

const useSignInHooks = ({ isSignInRoute }: { isSignInRoute: boolean }) => {
  const { checkForCognitoUser, error: emailIDCheckApiError } = useCheckCognitoUser();
  const {
    apiError: legacyEmailSentError,
    emailSent: legacyEmailSent,
    sendLegacyEmailLink,
  } = useLegacySignInLink({ isSignInRoute });
  const { loginCognitoUser } = useCognitoLogin();
  const { getRedirectLink } = useCreateDeepLink();

  return {
    checkForCognitoUser,
    emailIDCheckApiError,
    getRedirectLink,
    legacyEmailSent,
    legacyEmailSentError,
    loginCognitoUser,
    sendLegacyEmailLink,
  };
};

export default useSignInHooks;
