import { Field } from 'react-final-form';

import { MDText } from 'i18n-react';

import { Toggle } from '@motorway/mw-highway-code/alpha';

import cypressIds from 'CypressId';

import { applyCypressData } from 'Utilities/index';

import LocalTexts from '../../AuthenticationTexts.json';

import styles from './ConsentField.scss';

const LocalT = new MDText(LocalTexts);

const FIELD_NAME = 'consent';

const text = {
  description: LocalT.translate('components.consentField.description'),
  info: LocalT.translate('components.consentField.info'),
  label: LocalT.translate('components.consentField.label'),
};

const ConsentField = () => (
  <div className={styles.component}>
    <div className={styles.consentField}>
      <div>{text.description}</div>
      <Field
        id={FIELD_NAME}
        initialValue={'true'}
        name={FIELD_NAME}
        type="checkbox"
      >
        {({ input }) => (
          <Toggle
            formProps={{
              input: {
                ...input,
                ...applyCypressData(cypressIds.fields.checkBoxes),
              },
            }}
            id={FIELD_NAME}
            label={text.label as string}
          />
        )}
      </Field>
    </div>
    <p>{text.info}</p>
  </div>
);
export default ConsentField;
