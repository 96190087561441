import React from 'react';
import { Field } from 'react-final-form';

import { MDText } from 'i18n-react';

import { FeedbackTooltip } from '@motorway/motorway-storybook-cra';
import { Input } from '@THC/components/Input';

import cypressIds from 'CypressId';

import { composeValidators, getFieldStateWithSubmissionErrors, requiredValidator } from 'Utilities/formValidators';
import { applyCypressData } from 'Utilities/index';

import LocalTexts from '../../AuthenticationTexts.json';

import styles from './NameField.scss';

const LocalT = new MDText(LocalTexts);

type Props = {
  initial?: string;
  onReset?: () => void;
};

const texts = {
  label: LocalT.translate('components.nameField.label'),
  nameValidation: LocalT.translate('components.nameField.errorMessage'),
  placeholder: LocalT.translate('components.nameField.placeholder'),
};

const FIELD_NAME = 'name';

const NameField = ({ initial }: Props) => (
  <Field
    id={FIELD_NAME}
    initialValue={initial}
    name={FIELD_NAME}
    type='text'
    validate={composeValidators(
      requiredValidator(texts.nameValidation),
    )}
  >
    {({ input, meta, ...props }) => (
      <FeedbackTooltip
        className={styles.tooltip}
        content={meta.error}
        intent={getFieldStateWithSubmissionErrors(meta)}
      >
        <Input
          formProps={{
            ...props,
            input: {
              ...input,
              ...applyCypressData(cypressIds.fields.nameField),
              autoComplete: FIELD_NAME,
              autoFocus: true,
              placeholder: texts.placeholder,
            },
            meta: {
              ...meta,
              active: undefined,
            },
          }}
          fullWidth={true}
          id={ FIELD_NAME }
          intent={getFieldStateWithSubmissionErrors(meta)}
          label={texts.label as string}
          showLabel={true}
          size='medium'
        />
      </FeedbackTooltip>
    )}
  </Field>
);

export default NameField;
